<template>
  <v-sheet
    min-height="280px"
    min-width="300px"
    height="100%"
    width="900px"
    elevation="5"
    class="d-sm-flex flex-row-reverse justify-center rounded-xl mx-2"
  >
    <!-- <v-card-text> -->
    <v-sheet
      width="45%"
      class="
        flex-grow-3
        d-flex
        justify-center
        align-center
        blue
        rounded-l-xl
        px-auto
      "
    >
      <div class="hidden">
        <img src="/image/Logo3.png" alt="موعود" class="justify-center" />
      </div>
    </v-sheet>
    <v-sheet
      class="
        my-auto
        flex-grow-1
        transparent
        d-flex
        flex-column
        justify-space-around
        align-strech
      "
    >
      <!-- v-tittle -->
      <v-card-title class="justify-center"> ورود به پنل کاربری </v-card-title>
      <!-- v-form -->
      <v-form
        @submit.prevent="validate"
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <!-- v-form username input field -->
        <v-text-field
          :error-count="errors.username.messages.lenght || 1"
          :messages="errors.username.messages"
          :error="errors.username.status"
          rounded
          outlined
          clearable
          class="mx-5 font-weight-light login__text-field"
          :background-color="
            $vuetify.theme.dark ? 'gray lighten-1' : 'blue lighten-5'
          "
          color="blue"
          prepend-inner-icon="mdi-account"
          v-model.trim="login.username"
          hint="ایمیل یا کدملی یا موبایل"
          label="نام کاربری"
          :rules="[rules.required(login.username, 'نام کاربری'), rules.english]"
        >
        </v-text-field>

        <!-- v-form password input field -->
        <v-input>
          <v-text-field
            :error-count="errors.password.messages.lenght || 1"
            :messages="errors.password.messages"
            :error="errors.password.status"
            rounded
            outlined
            clearable
            color="blue"
            class="mx-5 font-weight-light login__text-field"
            :background-color="
              $vuetify.theme.dark ? 'gray lighten-1' : 'blue lighten-5'
            "
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            prepend-inner-icon="mdi-lock-outline"
            @click:append="show = !show"
            label="گذرواژه"
            :type="show ? 'text' : 'password'"
            v-model.trim="login.password"
            :rules="[rules.required(login.password, 'گذرواژه'), rules.english]"
          >
            >
          </v-text-field>
        </v-input>
        <!-- v-input -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            type="submit"
            depressed
            large
            x-large
            rounded
            color="blue"
            class="mx-5 white--text"
            :loading="formIsLoading"
            :disabled="formIsLoading"
          >
            ورود
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-sheet>
  </v-sheet>
  <!-- </v-card-text> -->
</template>

<script>
import store from "@/store/index.js";

export default {
  name: "LoginCard",
  data() {
    return {
      valid: false,
      show: false,
    };
  },
  computed: {
    rules: () => ({
      required: store.state.rules.required,
      english: store.state.rules.englishChar,
    }),
    formIsLoading: {
      get: () => store.state.auth.loginFormIsLoading,
      set: (newValue) => newValue,
    },
    login: () => store.state.auth.loginForm,
    errors: {
      get: () => store.state.auth.loginFormErrors,
      set: (newValue) => newValue,
    },
  },
  methods: {
    async validate() {
      this.clearLoginErrors();
      if (this.$refs.form.validate()) {
        store.dispatch("auth/loginProcess");
      }
    },

    clearLoginErrors() {
      store.commit("auth/setLoginErrors", {
        username: { status: false, messages: [] },
        password: { status: false, messages: [] },
      });
    },
  },
  watch: {
    "login.username"() {
      this.clearLoginErrors();
    },
    "login.password"() {
      this.clearLoginErrors();
    },
  },
};
</script>

<style  >
@media only screen and (max-width: 600px) {
  .hidden {
    display: none !important;
  }
}
.login__text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  unicode-bidi: bidi-override;
  direction: ltr;
  text-align: left;
  font-family: sans-serif;
}
.v-messages__wrapper {
  direction: rtl;
}
</style>